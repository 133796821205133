.container-policy {
    border: 2px solid #d9d9d9;
    border-radius: 8px;
    padding: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 20px auto;
}

.container-policy h1 {
    text-align: center;
    margin-bottom: 24px;
}

.container-policy h2 {
    color: #6d6d6d;
    margin-top: 20px;
}

.container-policy p {
    margin-bottom: 10px;
}

.container-policy ul {
    margin: 10px 0;
    padding-left: 20px;
}
