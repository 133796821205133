label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body {
  font-family: Barlow Condensed, sans-serif;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
}

html, body {
  height: 100%;
  margin: 0; 
}

.main-container {
  flex: 1;
  align-items: center;
  background-color: #f9f9f9;
  min-height: 50%;
}

header, footer {
  background-color: #282c34;
  color: white;
  text-align: center;
  padding: 1rem;
}

section {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
