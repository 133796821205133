.store-card {
    background-color: #fff;
    border: 1px solid #c6c6c6;
    border-radius: 10px;
    padding: 30px;
    text-align: left;
    flex: 1 1 calc(50% - 20px);
    min-width: 300px;
    max-width: 50%;
    position: relative;
    padding-bottom: 80px;
    justify-content: space-between;
 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.clothes-kind-gender::before,
.clothes-types::before {
    content: '';
    display: block;
    height: 2px;
    width: 50px;
    background-color: #d9d9d9;
    margin: 10px auto;
}

.store-button-container {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    display: grid;
    justify-content: center;
}

.store-name {
    font-size: 24px;
    color: #000;
    margin: 0 0 15px 0;
    font-weight: lighter;
    text-align: center;
}

.store-info, .info-label, .info-value {
    margin-bottom: 10px;
    font-size: 18px;
}

.info-label {
    font-weight: bold;
    color: #555;
}

.info-value {
    margin-left: 10px;
    color: #333;
}

.store-button {
    background-color: #fff;
    color: #6d6d6d;
    border: 1px solid #c6c6c6;
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    width: max-content;
}

.store-button:hover {
    background-color: #6d6d6d;
    color: #fff;
}

.tag-chip {
    margin: 3px;
    padding: 6px 8px;
    background-color: #fff;
    border: 1px solid #ffcc00;
    border-radius: 15px;
    font-size: 0.75rem;
}

@media screen and (max-width: 768px) {

    .store-card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .title {
        font-size: 2.5em;
    }
}

@media screen and (max-width: 480px) {
    .store-card {
        flex: 1 1 100%;
    }

    .title {
        font-size: 3em;
    }
}

.tooltip {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 10;
    margin-bottom: 12px;
}
.affiliate-container:hover .tooltip,
.affiliate-container:focus-within .tooltip {
    display: block;
}
