@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Playfair+Display:wght@700&display=swap');

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #fafafa;
    color: #333;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    padding: 0 20px;
    margin: 0 auto; 
}

.heading
{
    padding-top: 160px;
    padding-bottom: 190px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.heading .title {
    font-family: 'Playfair Display', serif;
    text-align: center;
    font-size: 3em;
    color: #6d6d6d;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.scrollButton {
    padding: 12px 24px;
    font-size: 1.2em;
    background-color: #6d6d6d;
    color: white;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  } 

.scrollButton:hover {
    color: #ddd;
    border-color: #e6b800;
}

.subtitle {
    text-align: center;
    font-size: 1em;
    color: #6d6d6d;
    margin-bottom: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.stores-search-input {
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.clear-filters {
    padding: 1px 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    padding: 4px 10px; 
    min-height: calc(1.2em + 10px);
}

.clear-filters:hover {
    font-weight: bold;
  }

.filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    min-height: 100px;
}

.chips-section {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: left;
    padding-left: auto;
}

.filter-chip {
    margin: 3px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
    cursor: pointer;
    background-color: #fff;
}

.filter-chip {
    transition: background-color 0.3s, color 0.3s;
}

.filter-chip.selected, .filter-chip:hover {
    background-color: #6d6d6d;
    color: #fff;
}

.show-more-button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
    font-size: 0.8em;
}

.tags-filters button {
    border: 1px solid #ffcc00;
    background-color: white;
    color: #000;
    font-size: 0.75rem;
    padding: 6px 8px;
    cursor: pointer;
}

.tags-filters button:hover {
    background-color: #e6b800;
    color: #000;
}

.tags-filters button.selected{
    background-color: #ffcc00;
    color: #000;
    outline: 2px solid #e6b800;
}

.results {
    text-align: center;
    min-height: 300px;
    max-width: 100%;
    width: 100%;
}

.chips-filter:last-child {
    border-bottom: none;
}

.results-title, .filters-sidebar .title {
    font-size: 1.3em;
    margin: 0;
    padding: 20px 0;
    text-align: left;
    width: 100%;
    color: #555!important;
    font-family: 'Roboto', sans-serif;
    align-items: center;
}

.store-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    align-items: stretch;
}

.store-info, .info-label, .info-value {
    margin-bottom: 10px;
    
    font-size: 18px;
}

.info-label {
    font-weight: bold;
    color: #555;
}

.info-value {
    margin-left: 10px;
    color: #333;
}

.stores-count{
    text-align: left;
    padding-bottom: 10px;
}

.loading, .error, .no-stores {
    text-align: center;
    padding-top: 50px;
    font-size: 1.4em;
    color: #ff4081;
}

.no-stores {
    color: #7f8c8d;
    padding-top: 5%;
}

.pagination{
    padding-bottom: 10px;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.pagination button {
    margin: 0 5px;
    padding: 8px 12px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:hover {
    background-color: #ddd;
}

.pagination button.active {
    background-color: #7f8c8d;
    color: white;
}

.pagination button:disabled {
    background-color: #e9ecef;
    cursor: not-allowed;
}

@media screen and (min-width: 769px) {
    .mobile-only {
        display: none;
    }
    
    .chips-filter {
        padding-top: 1em;
    }
    
    .results {
        margin-top: 0px;
    }
}


@media screen and (max-width: 480px) {
    .store-card {
        flex: 1 1 100%;
    }
}

@media (max-width: 768px) {
    .pagination {
        flex-wrap: wrap;
        justify-content: center;
    }

    .pagination button {
        padding: 6px 10px;
        font-size: 14px;
    }
}

.filters-sidebar {
    max-width: 100%;
    max-width: 350px;
    min-width: 350px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    margin-right: 20px;
    margin-top: 70px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-height: 500px;
    overflow-y: auto;
    position: relative;
    margin-bottom: 20px;
    top: 0;
    left: 0; 
}

.filters-sidebar .chips-section {
    display: flex;
    gap: 2px;
}

.chips-filter {
    min-width: 100px;
    align-items: flex-start;
    justify-content: left;
    max-width: 100%;
    border-bottom: 1.1px solid #ddd;
    width:100%;
    padding-bottom: 1em;
    border-radius: 2px; 
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
}

.chips-filter>input
{
    margin-bottom: 16px;
    max-width: 95%;
}

.layout {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
    margin-top: 20px;
  }
    
  .store-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-start;
      padding-bottom: 20px;
  }

  
@media screen and (max-width: 768px) {
   
    .desktop-only {
        display: none;
    }

    .results-title{
        text-align: left;
        padding-left: auto;
        padding-right: 0;
    }    
    
    .store-card {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .heading .title {
        font-size: 2.2em;
    }

    .chips-section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: left;
        padding-left: auto;
        padding-right: auto;
    }

    .chips-filter {
        border-bottom: none;
        min-width: 100px;
        align-items: flex-start;
        justify-content: left;
        max-width: 100%;
    }

    .filter-chip {
        margin: 3px;
        padding: 8px 10px;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 15px;
        cursor: pointer;
    }


    .filter-chip:hover {
        background-color: inherit;
        color: inherit;
    }

    .filter-chip.selected,
    .filter-chip:focus {
        background-color: #6d6d6d;
        color: #fff;
    }
    
    .tags-filters button:hover {
        background-color: inherit;
        color: inherit;
    }

    .tags-filters .filter-chip.selected,
    .tags-filters .filter-chip:focus {
        background-color: #ffcc00;
        color: #000;
    }

    .heading
    {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .subtitle {
        text-align: center;
        font-size: 1em;
        color: #6d6d6d;
        margin-bottom: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    .results-title {
        font-size: 1.1em;
    }
    .layout {
        flex-direction: column;
        gap: 20px;
    }
    .store-list {
        margin-top: 20px;
    }

    .filters-sidebar {
        max-width: 100%;
        background-color: #f9f9f9;
        border: none;
        margin-top: 10px;
        box-shadow: none;
        min-height: 500px;
        overflow-y: auto;
        position: relative;
        margin-bottom: 0px;
        top: 0;
        left: 0; 
        padding: 10px;
    }

    
    .filters-sidebar .title {
        font-size: 1.1em;
    }
}

.sort-section {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: 0;
    gap:5px;
    font-size: 1.2em;
    align-items: center;
    margin-bottom: 10px;
}

.sort-section label {
    color: #555;  
    height: 100%;
    justify-self: first baseline;
    margin-bottom: 8px;
}

.sort-section select {
    padding: 6px  6px;
    padding-right: 0px;
    align-self: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    color: #333;
    width: auto;
    transition: border-color 0.3s ease;
    height: 100%;
}

.sort-section select:focus {
    border-color: #6d6d6d;
}

@media screen and (max-width: 768px) {
    .sort-section {
        width: fit-content;
        margin-bottom: -8px;
        height: fit-content;
    }

    .sort-section select option {
        font-size: 0.7em;
        max-width: 80%;
        text-align: center;
    }   

    .sort-section select {
        padding-bottom:6px;
        padding-top:6px;
    }
}
