.navbar {
  align-items: center;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 10rem;
  position: relative;
  background-color: #555;
}

.logo-mobile {
  display: none;
}

.logo-desktop {
  display: block;
}

.navbar .logo img {
  max-width: 150px;
  width: 100%;
  height: auto;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 20px;
  justify-content: right;
  flex-grow: 1;
}

.navbar-menu li {
  display: inline;
}

.navbar-menu a {
  text-decoration: none;
  color: #ffffff;
  transition: color 0.3s;
  padding: 10px 15px;
}

.navbar-menu a:hover {
  color: #ffcc00;
}

.navbar-toggle {
  display: none;
  background-color: transparent;
  border: none;
  font-size: 28px;
  color: white;
  cursor: pointer;
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 90%;
    right: 0;
    background-color: #c6c6c6;
    width: 100%;
    padding: 1rem;
  }

  .navbar-menu.active {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar {
    justify-content: space-between;
    padding: 0 20px;
  }

  .logo-mobile {
    display: block;
  }

  .logo-desktop {
    display: none;
  }
}
