body {
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    color: #333;
}

.hero {
    background: linear-gradient(135deg, #A8DADC, #457B9D);
    color: #fff;
    text-align: center;
    padding: 100px 20px;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 30px 30px 30px 30px;
}

.hero h1 {
    font-size: 2.5rem;
    margin: 0;
    font-weight: 700;
    letter-spacing: 1px;
}

.intro {
    display: flex;
    justify-content: center;
    background-color: #fff;
    padding: 60px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    border-radius: 20px;
}

.intro-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    align-items: center;
    gap: 40px;
}

.image-container {
    flex: 1;
    display: flex;
    justify-content: center;
}

.fashion-image {
    width: 100%;
    max-width: 500px;
    height: auto;
    border-radius: 20px;
    object-fit: cover;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.text-container {
    flex: 1;
}

.intro-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #1D3557;
}

.intro-description {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #555;
}

.show {
    display: inline-block;
    background: #1D3557;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    transition: background 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 40px auto;
    cursor: pointer;
    text-decoration: none;
}

.show a {
    color: #fff;
    text-decoration: none;
}

.show:hover {
    background: #457B9D;
}

.show a:hover {
    color: #eaeaea;
}

@media (max-width: 768px) {
    .intro-container {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    
    .hero h1 {
        font-size: 2rem;
    }

    .intro-description {
        font-size: 1rem;
    }

    .image-container {
        max-width: 100%;
    }
}
