.modal-cookies {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 90%;
    max-width: 500px;
    text-align: center;
  }
  
  .cookies-checkboxes {
    margin: 20px 0;
  }
  
  .cookies-checkboxes div {
    margin-bottom: 10px;
  }
  
  .buttons-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .accept-button {
    background-color: #f39c12;
    color: #fff;
  }

  .button{
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .accept-button:hover {
    background-color: #e67e22;
  }
  
  button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }

  .customize-button {
    background-color: #bdc3c7!important;
  } 
  
  .customize-button:hover {
    background-color: #95a5a6!important;
  }
  
  .save-button {
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
  }

  .save-button:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
  }

  .cookies-checkboxes {
    margin: 20px 0;
    text-align: left;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .cookies-checkboxes input{
    width:20%;
  }

  .cookies-checkboxes div {
    display: flex;
    align-items: left;
    gap: 2px;
  }
  
  .modal-content a{
    text-decoration: none;
  }