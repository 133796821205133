.seoSection {
  display: flex;
  background-color: #e0e0e0;
  color: #333;
  padding-bottom: 20px;
  max-width: 100%;
  justify-content: center;
  font-size: 1.1em;
}

.seoContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
}

.column1 {
  flex: 1;
  padding-right: 2rem;
  
  font-size: 0.8em;
}

.column2 {
  flex: 2;
  max-width: 60%;
}

.description-text {
  padding-bottom: 5px;
}

.option {
  padding-bottom: 10px;
}

.option-title {
  padding-bottom: 5px;
  text-decoration: none;
  font-size: 1.1em;
}

.option-title a{
  color:inherit;
}

.clothes-options {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
  text-decoration: none;
  align-items: center;
  padding-bottom: 0;
}

.clothes-options .item {
  display: inline-block;
  font-size: 1.1em;
  text-decoration: none;
  font-weight: normal;
  color: #333;
}

.clothes-options .item a:hover {
  text-decoration: underline;
}

.clothes-options .item .option-title {
  font-weight: normal;
  font-size: 1em !important;
  color: inherit;
}

.clothes-options a:visited {
  color: inherit;
}

.tags-options .item {
  display: inline-block;
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: normal;
  padding-top: 2px;
}

.tags-options .item .option-title {
  color: inherit;
}

.tags-options .separator {
  margin: 0 5px;
  font-size: 1.1rem;
  color: #333;
  line-height: normal;
}

.tags-options a:visited {
  color: inherit;
}

.tags-options a:hover {
  text-decoration: underline;
}

.sectionTitle {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
  margin-bottom: 5px;
}

.seoGroup {
  justify-content: left;
  padding-left: auto;
  font-size: 0.77em;
}

.seoGroup .title {
  padding-bottom: 3px;
  margin-bottom: 0;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 1.5;
  cursor: pointer;
  text-decoration: none;
}

.separator {
  margin: 0 5px;
  font-size: 20px;
  color: #333;
  line-height: normal;
}

@media (max-width: 768px) {
  .seoContent {
    flex-direction: column; /* Zmienia układ na kolumnowy na mniejszych ekranach */
    margin: auto;
    max-width: 90%;
  }

  .column1,
  .column2 {
    max-width: 100%;
    padding-right: 0;
  }
}
