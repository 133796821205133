.footer {
  background-color: #f0f0f0;
  color: #cccccc;
  padding: 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 60%;
  max-width: 1200px;
  flex-wrap: wrap;
}

.footer-links {
  flex: 1;
  margin-right: 2rem;
  text-align: left;
}

.footer-links h4 {
  margin-bottom: 1rem;
  color: #FF4081;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.footer-links li {
  margin: 0.5rem 1rem 0.5rem 0;
}

.footer-links a {
  color: #333333 ;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #ffcc00;
}

.footer-info {
  flex: 1;
  text-align: center;
  margin-top: auto;
}

.footer-info p {
  margin: 0;
  font-size: 0.9rem;
  color: #888888;
}

@media (max-width: 768px) {
  .footer-content {
    width: 100%;
    flex-direction: column;
    align-items: left;
    padding-left: 20px;
  }

  .footer-links ul {
    flex-direction: column;
  }

  .footer-links li {
    margin: 0.5rem 0;
  }

  .footer-info {
    margin-top: 2rem;
  }
}

.cookie-settings-button {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
}
.cookie-settings-button:hover {
  color: darkblue;
}
