.contact-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;  
  /* margin-top: 60px; */
}

.pre-contact-container {
  padding-top: 60px;
}

.contact-content {
  display: flex;
  flex-wrap: wrap; 
}

.text-container {
  max-width: 40%;
  margin-right: 20px; 
}

.form-container {
  flex: 1; 
}

h1 {
  text-align: left;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input, textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.send-button {
  width: 50%;
  padding: 10px;
  background-color: #555;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.send-button:hover {
  background-color: white;
  color: #555;
  border: 1px solid #555;
}

.send-button:disabled {
  background-color: grey;
  color: grey;
}

.success-message {
  color: green;
  text-align: center;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .contact-content {
    display: block;
  }

  .text-container, .form-container {
    max-width: 100%;
    margin: 0;
  }

  .form-container {
    margin-top: 20px;
  }
}